import React, { useEffect, useState } from "react";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { useDispatch, useSelector } from "react-redux";
import * as MachineSelector from "../../store/Machine/selectors";
import { companys } from "../../store/Auth/selectors";
import moment from "moment";
import "moment-timezone";
import Grid from "@mui/material/Grid";
import { Box, Button, Skeleton } from "@mui/material";
import "./style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Photo from "../../assets/photo.svg";
import Doc from "../../assets/doc.svg";
import Manual from "../../assets/manual.svg";
import IconTable from "../../assets/icons_table.svg";
import { Fuel, Preloader } from "../../components";
import scheme from "../../assets/scheme.svg";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  getMachine,
  getMachineFuel,
  getMachineOwner,
  getMachineTelemetry,
  getMachineWork,
  setMachineFuel,
  setMachineOwner,
  setMachineTelemetry,
  setMachineWork,
} from "../../store/Machine/actions";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { usePromiseTracker } from "react-promise-tracker";

function Machine() {
  const { nomer } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { promiseInProgress } = usePromiseTracker();

  const userCompanys = useSelector(companys);

  const machine = useSelector(MachineSelector.machine);
  const telemetry = useSelector(MachineSelector.telemetry);
  const machineFuel = useSelector(MachineSelector.machineFuel);
  const machineWork = useSelector(MachineSelector.machineWork);
  const machineOwner = useSelector(MachineSelector.machineOwner);

  const [diff, setDiff] = useState(0);

  const [nextTO, setNextTO] = useState(0);
  const [dayTO, setDayTO] = useState("");

  const checkCompany = () => {
    let listIdCompany = [1, 12, 16, 15];
    if (typeof userCompanys === "undefined" || userCompanys.length == 0)
      return false;
    for (let company of userCompanys) {
      if (listIdCompany.indexOf(company) !== -1) return true;
    }
    return false;
  };

  useEffect(() => {
    dispatch(getMachine(nomer));
    dispatch(getMachineTelemetry(nomer));
    dispatch(getMachineFuel(nomer));
    dispatch(getMachineWork(nomer));

    return () => {
      dispatch(setMachineTelemetry([]));
      dispatch(setMachineFuel([]));
      dispatch(setMachineWork([]));
      dispatch(setMachineOwner([]));
    };
  }, [nomer]);

  useEffect(() => {
    if (machine.owner) dispatch(getMachineOwner(machine.owner));
    setNextTO(0);
    setDayTO("");
    if (
      typeof machine.period_to !== "undefined" &&
      machine.period_to != null &&
      typeof machine.period_to_title !== "undefined" &&
      machine.period_to_title != null &&
      typeof telemetry.motoHours !== "undefined" &&
      telemetry.motoHours != null
    ) {
      setNextTO(
        (Math.floor(
          Number(telemetry.motoHours) / Number(machine.period_to_title)
        ) +
          1) *
          Number(machine.period_to_title)
      );
      if (machine.date_next_to != null) {
        var days = moment(machine.date_next_to).diff(moment(), "days");
        setDayTO(
          `через ${days} ${
            days === 1
              ? "день"
              : days % 100 > 4 && days % 100 < 20
              ? "дней"
              : days % 10 < 5
              ? "дня"
              : "дней"
          }`
        );
      }
    }
  }, [machine]);

  useEffect(() => {
    if (typeof telemetry.time !== "undefined") {
      setDiff(moment().diff(getMomentInUser(telemetry.time), "days"));
    }
    setNextTO(0);
    setDayTO("");
    if (
      typeof machine.period_to !== "undefined" &&
      machine.period_to != null &&
      typeof machine.period_to_title !== "undefined" &&
      machine.period_to_title != null &&
      typeof telemetry.motoHours !== "undefined" &&
      telemetry.motoHours != null
    ) {
      setNextTO(
        (Math.floor(
          Number(telemetry.motoHours) / Number(machine.period_to_title)
        ) +
          1) *
          Number(machine.period_to_title)
      );
      if (machine.date_next_to != null) {
        var days = moment(machine.date_next_to).diff(moment(), "days");
        setDayTO(
          `через ${days} ${
            days === 1
              ? "день"
              : days % 100 > 4 && days % 100 < 20
              ? "дней"
              : days % 10 < 5
              ? "дня"
              : "дней"
          }`
        );
      }
    }
  }, [telemetry]);

  const getMomentInUser = (datetime) => {
    return moment
      .utc(datetime)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
  };

  const mapState = {
    center: [telemetry.lat, telemetry.lon],
    zoom: 9,
    behaviors: ["default", "scrollZoom"],
    controls: [],
  };

  ChartJS.register(Title, Tooltip, Legend, ArcElement);

  const getDataWork = (data) => {
    if (
      typeof data.idling === "undefined" ||
      typeof data.activity === "undefined" ||
      data.activity == 0
    )
      return [0, 0, 0];
    const idling =
      data.idling !== 0 ? Math.round((data.idling * 100) / 86400) : 0;
    const activity =
      data.activity !== 0 ? Math.round((data.activity * 100) / 86400) : 0;
    const noInfo = 100 - activity - idling;
    return [activity, idling, noInfo];
  };

  const getDataPower = (data) => {
    if (
      typeof data.power === "undefined" ||
      typeof data.activity === "undefined" ||
      data.activity == 0
    )
      return [0, 0, 0];
    const min =
      data.power.min !== 0
        ? Math.round((data.power.min * 100) / data.activity)
        : 0;
    const max =
      data.power.max !== 0
        ? Math.round((data.power.max * 100) / data.activity)
        : 0;
    const hight = 100 - max - min;
    return [hight, min, max];
  };

  const HiddenBlock = ({ type, children }) => {
    const list = [11, 13];
    if (list.includes(type)) {
      return null;
    } else {
      return children;
    }
  };

  const ShowBlock = ({
    type,
    blockType = "worckInfo" | "table" | "machineWorck" | "machineLoad",
    children,
  }) => {
    const list = {
      11: ["worckInfo", "table"],
      13: ["worckInfo", "table", "machineWorck", "machineLoad"],
    };

    if (type && list[type] && list[type].includes(blockType)) {
      return children;
    } else {
      return null;
    }
  };

  const optionsChart = {
    cutout: "55%",
    maintainAspectRatio: false,
    layout: {
      autoPadding: false,
      padding: 0,
    },
    plugins: {
      tooltip: {
        usePointStyle: true,
        callbacks: {
          label(label) {
            return `${label.formattedValue}%`;
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  if (promiseInProgress) return <Preloader />;

  return (
    <Box sx={{ flex: 1, padding: "24px" }}>
      <Grid
        container
        columnSpacing={3}
        sx={{
          height: "100%",
        }}
      >
        <Grid
          item
          xs={7}
          sx={{
            height: "100%",
          }}
        >
          <Grid
            container
            rowSpacing={3}
            columnSpacing={3}
            sx={{
              height: "100%",
            }}
          >
            <Grid item xs={4}>
              {typeof machine.nomer !== "undefined" ? (
                <div className="machine-info">
                  <h1>
                    {machine.type} {machine.nomer}
                  </h1>
                  <p className="machine-info-model">
                    {machine.model}
                    {machine.complectation !== null &&
                    machine.complectation != ""
                      ? "." + machine.complectation
                      : ""}
                  </p>
                  <p className="machine-info-class">{machine.classes}</p>
                  <div className="machine-info-footer">
                    <div className="machine-info-footer-item">
                      <label>Наработка</label>
                      <p>{telemetry.motoHours} м/ч</p>
                    </div>
                    <div
                      className="machine-info-footer-item"
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {diff >= 3 || typeof telemetry.time === "undefined" ? (
                        <WarningAmberIcon
                          sx={{
                            mr: "8px",
                            color: "red",
                          }}
                        />
                      ) : null}
                      {typeof telemetry.time !== "undefined" ? (
                        <label>
                          Последние данные
                          <br />
                          {getMomentInUser(telemetry.time).format(
                            "DD.MM.YYYY HH:mm:ss"
                          )}
                        </label>
                      ) : (
                        <label>
                          Последние данные
                          <br />
                          Нет данных
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="machine-info">
                  <h1
                    style={{
                      display: "flex",
                    }}
                  >
                    Бульдозер{" "}
                    <Skeleton
                      sx={{ marginLeft: "10px" }}
                      variant="rectangular"
                      width={45}
                      height={22}
                    />
                  </h1>
                  <p className="machine-info-model">
                    <Skeleton variant="rectangular" width={65} height={19} />
                  </p>
                  <p className="machine-info-class">
                    <Skeleton variant="rectangular" width={130} height={19} />
                  </p>
                  <div className="machine-info-footer">
                    <div className="machine-info-footer-item">
                      <label>Наработка</label>
                      <p>
                        <Skeleton
                          variant="rectangular"
                          width={73}
                          height={19}
                        />
                      </p>
                    </div>
                    <div className="machine-info-footer-item">
                      <label>
                        Последние данные
                        <br />
                        <Skeleton
                          variant="rectangular"
                          width={128}
                          height={19}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
            <HiddenBlock type={machine.type_id}>
              <Grid item xs={4}>
                <div className="machine-service">
                  <h3>Сервисное обслуживание</h3>
                  {nextTO > 0 && dayTO.length > 0 ? (
                    <Box
                      sx={{
                        fontSize: "18px",
                        color: "#fff",
                        fontWeight: "700",
                      }}
                    >
                      {nextTO} м/ч
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          display: "inline-block",
                          marginLeft: "8px",
                        }}
                      >
                        {dayTO}
                      </Box>
                    </Box>
                  ) : null}
                  <Button
                    onClick={() => navigate(`/machine/${nomer}/request/create`)}
                    variant="contained"
                    color="custom_blue"
                    sx={{
                      fontSize: "14px",
                      borderRadius: "20px",
                      marginTop: "29px",
                      textTransform: "none",
                      lineHeight: "30px",
                    }}
                  >
                    Создать заявку на ТО или ремонт
                  </Button>

                  <Box
                    sx={{
                      marginTop: "auto",
                      color: "#fff",
                    }}
                    color="custom_white"
                  >
                    Архив
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        onClick={() => navigate(`/machine/${nomer}/requests`)}
                        variant="outlined"
                        color="custom_white"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "400",
                          borderRadius: "20px",
                          lineHeight: "15px",
                          textTransform: "none",
                        }}
                      >
                        Заявки
                      </Button>
                      <Button
                        onClick={() =>
                          navigate(`/machine/${nomer}/requests?param=1`)
                        }
                        variant="outlined"
                        color="custom_white"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "400",
                          borderRadius: "20px",
                          lineHeight: "15px",
                          textTransform: "none",
                        }}
                      >
                        ТО
                      </Button>
                      <Button
                        onClick={() =>
                          navigate(`/machine/${nomer}/requests?param=2`)
                        }
                        variant="outlined"
                        color="custom_white"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "400",
                          borderRadius: "20px",
                          lineHeight: "15px",
                          textTransform: "none",
                        }}
                      >
                        Ремонт гарантия
                      </Button>
                    </Box>
                  </Box>
                </div>
              </Grid>
            </HiddenBlock>
            <HiddenBlock type={machine.type_id}>
              <Grid item xs={4}>
                <div>
                  <div className="catalog">
                    <h3>Каталог запасных частей</h3>
                    <div className="catalog-btn-wrapper">
                      <Button
                        component={Link}
                        to={`/shop/${nomer}`}
                        variant="outlined"
                        color="custom_white"
                        sx={{
                          fontSize: "13px",
                          borderRadius: "20px",
                        }}
                      >
                        Перейти
                      </Button>
                      <Button
                        component={Link}
                        to={`/shop/orders/`}
                        variant="outlined"
                        color="custom_white"
                        sx={{
                          fontSize: "13px",
                          borderRadius: "20px",
                        }}
                      >
                        Заказы
                      </Button>
                    </div>
                  </div>
                  {checkCompany() ? (
                    <div className="noty">
                      <h3>Поддержка</h3>
                      <div className="catalog-btn-wrapper">
                        <Button
                          href="#"
                          variant="outlined"
                          color="custom_blue_outlined"
                          sx={{
                            fontSize: "13px",
                            borderRadius: "20px",
                          }}
                        >
                          Обращения
                        </Button>
                        <Button
                          component={Link}
                          to={`/machine/${nomer}/manual?type=3`}
                          variant="outlined"
                          color="custom_blue_outlined"
                          sx={{
                            fontSize: "13px",
                            borderRadius: "20px",
                          }}
                        >
                          FQA
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <Box
                      className="catalog"
                      sx={{
                        mt: "24px",
                        flex: 1,
                        color: "white",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <h3>В разработке</h3>
                    </Box>
                  )}
                </div>
              </Grid>
            </HiddenBlock>
            <ShowBlock type={machine.type_id} blockType={"worckInfo"}>
              <Grid item xs={4}>
                <div className="machine-stat">
                  <h3>Данные о работе</h3>
                  <Link to={`/machine/${nomer}/work`}>
                    <img src={scheme} alt="scheme" />
                  </Link>
                </div>
              </Grid>
            </ShowBlock>
            <ShowBlock type={machine.type_id} blockType={"table"}>
              <Grid item xs={4}>
                <div className="machine-stat card-item">
                  <h3>Таблица</h3>
                  <Link to={`/machine/${nomer}/maschine-work-table`}>
                    <img src={IconTable} alt="Таблица" />
                  </Link>
                </div>
              </Grid>
            </ShowBlock>

            <Grid item xs={4}>
              <Fuel
                data={machineFuel}
                width={180}
                nomer={nomer}
                diff={diff}
                fuel_volume={machine.fuel_volume}
              />
            </Grid>
            <HiddenBlock>
              <Grid item xs={4}>
                {typeof machineWork.idling === "undefined" ||
                typeof machineWork.activity === "undefined" ||
                machineWork.activity == 0 ||
                diff > 1 ? (
                  <Box
                    id="work"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p>Работа машины</p>
                    <span>За последние сутки</span>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "12px",
                        color: "#787878",
                      }}
                    >
                      <span>Нет данных</span>
                    </div>
                  </Box>
                ) : (
                  <Box id="work">
                    <p>Работа машины</p>
                    <span>За последние сутки</span>
                    <Box
                      sx={{
                        height: "140px",
                        width: "140px",
                        position: "relative",
                        margin: "0 auto",
                      }}
                    >
                      <Doughnut
                        data={{
                          labels: ["С нагрузкой", "Холостой ход", "Нет данных"],
                          datasets: [
                            {
                              data: getDataWork(machineWork),
                              backgroundColor: [
                                "#6DCC1D",
                                "#48A6E1",
                                "#E6E6E6",
                              ],
                              hoverOffset: 4,
                            },
                          ],
                        }}
                        options={optionsChart}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: "39%",
                          left: "0",
                          cursor: "default",
                          fontWeight: 700,
                          fontSize: 28,
                          textAlign: "center",
                          width: "100%",
                          zIndex: -1,
                        }}
                      >{`${
                        machineWork.activity !== 0
                          ? Math.round((machineWork.activity * 100) / 86400)
                          : 0
                      }%`}</Box>
                    </Box>
                    <div className="desc">
                      <div className="min">
                        <span></span>
                        <p>Нет данных</p>
                      </div>
                      <div className="mid">
                        <span></span>
                        <p>Холостой ход</p>
                      </div>
                      <div className="max">
                        <span></span>
                        <p>С нагрузкой</p>
                      </div>
                    </div>
                  </Box>
                )}
              </Grid>
            </HiddenBlock>
            <HiddenBlock>
              <Grid item xs={4}>
                {typeof machineWork.power === "undefined" ||
                typeof machineWork.activity === "undefined" ||
                machineWork.activity == 0 ||
                diff > 1 ? (
                  <Box
                    id="power"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p>Нагрузка машины</p>
                    <span>За последние сутки</span>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "12px",
                        color: "#787878",
                      }}
                    >
                      <span>Нет данных</span>
                    </div>
                  </Box>
                ) : (
                  <Box id="power">
                    <p>Нагрузка машины</p>
                    <span>За последние сутки</span>
                    <Box
                      sx={{
                        height: "140px",
                        width: "140px",
                        position: "relative",
                        margin: "0 auto",
                      }}
                    >
                      <Doughnut
                        data={{
                          labels: ["Низкая", "Средняя", "Высокая"],
                          datasets: [
                            {
                              data: getDataPower(machineWork),
                              backgroundColor: [
                                "#6DCC1D",
                                "#48A6E1",
                                "#EB622B",
                              ],
                              hoverOffset: 4,
                            },
                          ],
                        }}
                        options={optionsChart}
                      />
                    </Box>
                    <div className="desc">
                      <div className="min">
                        <span></span>
                        <p>Низкая</p>
                      </div>
                      <div className="mid">
                        <span></span>
                        <p>Средняя</p>
                      </div>
                      <div className="max">
                        <span></span>
                        <p>Высокая</p>
                      </div>
                    </div>
                  </Box>
                )}
              </Grid>
            </HiddenBlock>

            <HiddenBlock type={machine.type_id}>
              <Grid item xs={4}>
                <div className="items-wrapper">
                  <Link to={``} className="card-item">
                    <img src={Photo} alt="Фото" />
                    <p>Фото</p>
                  </Link>
                  <Link to={``} className="card-item">
                    <img src={Doc} alt="Документы" />
                    <p>Документы</p>
                  </Link>
                  <Link to={`/machine/${nomer}/manual`} className="card-item">
                    <img src={Manual} alt="Руководства" />
                    <p>Руководства</p>
                  </Link>
                  <Link
                    to={`/machine/${nomer}/maschine-work-table`}
                    className="card-item"
                  >
                    <img src={IconTable} alt="Таблица" />
                    <p>Таблица</p>
                  </Link>
                </div>
              </Grid>
            </HiddenBlock>
            <HiddenBlock type={machine.type_id}>
              <Grid item xs={4}>
                <div className="machine-stat">
                  <h3>Данные о работе</h3>
                  <Link to={`/machine/${nomer}/work`}>
                    <img src={scheme} alt="scheme" />
                  </Link>
                  {/* <Button
                                    href='#'
                                    variant="outlined"
                                    color="custom_blue_outlined"
                                    sx={{
                                        fontSize: "13px",
                                        borderRadius: "20px",
                                        marginTop: "20px"
                                    }}
                                >Сформировать отчет</Button> */}
                </div>
              </Grid>
            </HiddenBlock>
            <HiddenBlock type={machine.type_id}>
              <Grid item xs={4}>
                <div className="machine-owner-wrapper">
                  <h3>Информация о машине</h3>
                  <div className="owner">
                    <p>Владелец</p>
                    <span>
                      {typeof machineOwner.title !== "undefined" ? (
                        machineOwner.title
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          width={128}
                          height={19}
                        />
                      )}
                    </span>
                  </div>
                  <div className="owner-data">
                    {typeof machine.date_shipment !== "undefined" &&
                    machine.date_shipment !== null ? (
                      <p style={{ display: "flex" }}>
                        Дата отгрузки —{" "}
                        {moment(machine.date_shipment).format("DD.MM.YYYY")}
                      </p>
                    ) : null}
                    {typeof machine.date_startwork !== "undefined" &&
                    machine.date_startwork !== null ? (
                      <p style={{ display: "flex" }}>
                        Ввод в эксплуатацию —{" "}
                        {moment(machine.date_startwork).format("DD.MM.YYYY")}
                      </p>
                    ) : null}
                    {typeof machine.warranty !== "undefined" &&
                    machine.warranty !== null ? (
                      <p style={{ display: "flex" }}>
                        Гарантия — {machine.warranty.month} мес или{" "}
                        {machine.warranty.hours} м/ч
                      </p>
                    ) : null}
                  </div>
                  <Button
                    onClick={() => navigate(`/machine/${nomer}/detail`)}
                    variant="contained"
                    color="custom_blue"
                    sx={{
                      fontSize: "13px",
                      borderRadius: "20px",
                      marginTop: "20px",
                    }}
                  >
                    Подробнее
                  </Button>
                </div>
              </Grid>
            </HiddenBlock>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <YMaps
            query={{
              apikey: "be2343a6-8407-4405-81d3-603bb18c2d4e",
            }}
          >
            <Map
              state={mapState}
              width="100%"
              height="100%"
              modules={["package.full"]}
            >
              <Placemark
                geometry={[telemetry.lat, telemetry.lon]}
                properties={{
                  balloonContentBody: `${getMomentInUser(telemetry.time).format(
                    "DD.MM.YYYY HH:mm:ss"
                  )}`,
                }}
              />
            </Map>
          </YMaps>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Machine;
